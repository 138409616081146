import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import qs from "query-string"

import { detectMob } from "@src/Helper"
import Alert from "@components/Alert"
import Step1 from "@icons/sub-success-1.png"
import Step2 from "@icons/sub-success-2.png"
import Step3 from "@icons/sub-success-3.png"
import Success from "@icons/exm-payment-success-ic.png"

function PaymentSuccess(props) {
  const [id, setId] = useState("payment-success")
  const [isCore, setIsCore] = useState(true)

  const core = [
    "CORE010",
    "CORE011",
    "CORE012",
    "CORE001",
    "CORE002",
    "CORE003",
    "CORE004",
    "CORE005",
    "CORE006",
    "CORE007",
    "CORE008",
    "CORE009",
  ]

  useEffect(() => {
    const queryString = qs.parse(props.location.search)
    if (!core.includes(queryString.item_number)) {
      setIsCore(false)
      // navigate('/dashboard');
    }

    if (detectMob()) {
      setId("m-payment-success")
    }
  }, [])

  return (
    <div className="section-128" id={id}>
      <div className="container">
        <div className="row">
          {isCore && (
            <Alert
              style="info"
              className="mb-3 w-100"
              text={
                <div>
                  To get started, please verify and activate your Instagram
                  username through the Social Account by{" "}
                  <a
                    className="alert-link"
                    href="/socialaccount"
                    target="_blank"
                  >
                    clicking here
                  </a>{" "}
                  now.
                </div>
              }
            />
          )}
          <div
            className={`${isCore ? "col-lg-4" : "col-lg-12"} text-center`}
            style={id == "payment-success" ? { marginTop: 100 } : {}}
          >
            <img src={Success} alt="Executive Mafia Payment Success" />
            <h1 className="heading text-broccoli-green">Payment Success!</h1>
            <p className="caption text-200">
              Congratulations, your payment has been done successfully.
            </p>
            <Link
              to="/dashboard"
              className={`button ${
                id == "payment-success" ? "button-xl" : "button-md"
              } button-mafia-primary ${
                isCore ? "btn-block" : "d-inline-block"
              } mb-lg-0 mb-5`}
            >
              Go to Dashboard
            </Link>
          </div>
          {isCore && (
            <div className="col-lg-8">
              <h2 className="subtitle text-monday-blue">Get Started Now!</h2>
              <div className="card p-3">
                <div className="row">
                  <div className="col-sm-4 my-auto">
                    <img
                      src={Step1}
                      width="100%"
                      alt="Executive Mafia step 1 get started"
                    />
                  </div>
                  <div className="col-sm-8 my-auto">
                    <h2 className="subtitle text-navy-blue">Step 1</h2>
                    <p className="body mb-0">
                      Done activating your username? Now what you need to do is
                      to chat and click the start button on these bots:
                      <a
                        href="https://telegram.me/jesmin_bot"
                        target="_blank"
                        className="text-700 text-navy-blue"
                      >
                        @jesmin_bot
                      </a>
                      ,{" "}
                      <a
                        href="https://telegram.me/sofie_bot"
                        target="_blank"
                        className="text-700 text-navy-blue"
                      >
                        @sofie_bot
                      </a>
                      ,{" "}
                      <a
                        href="https://telegram.me/theoexm_bot"
                        target="_blank"
                        className="text-700 text-navy-blue"
                      >
                        @theoexm_bot
                      </a>
                      ,{" "}
                      <a
                        href="https://telegram.me/samexmbot"
                        target="_blank"
                        className="text-700 text-navy-blue"
                      >
                        @samexmbot
                      </a>
                      , and{" "}
                      <a
                        href="https://telegram.me/graceexmbot"
                        target="_blank"
                        className="text-700 text-navy-blue"
                      >
                        @graceexmbot
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="card p-3 my-3">
                <div className="row">
                  <div className="col-sm-4 my-auto">
                    <img
                      src={Step2}
                      width="100%"
                      alt="Executive Mafia step 1 get started"
                    />
                  </div>
                  <div className="col-sm-8 my-auto">
                    <h2 className="subtitle text-navy-blue">Step 2</h2>
                    <p className="body mb-0">
                      In 24 hours, our <strong>@ExecutiveMafiaAdmin</strong>{" "}
                      will reach out to you for the group invitation and niche
                      set-up through Telegram. Make sure your Telegram username
                      is the same as the username you registered on our website.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card p-3">
                <div className="row">
                  <div className="col-sm-4 my-auto">
                    <img
                      src={Step3}
                      width="100%"
                      alt="Executive Mafia step 1 get started"
                    />
                  </div>
                  <div className="col-sm-8 my-auto">
                    <h2 className="subtitle text-navy-blue">Step 3</h2>
                    <p className="body mb-0">
                      Last but not least, while waiting for our{" "}
                      <strong>@ExecutiveMafiaAdmin</strong> to reach out to you,
                      you can start joining the engagement rounds if you want
                      to. Please note that each engagement bot's rule is
                      different.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccess
