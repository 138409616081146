import React, { useState } from "react"
import { toast } from "react-toastify"
import qs from "query-string"

export const development =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"

// * 1616299199000 --> Sunday, March 21, 2021 03:59:59 GMT or Saturday, March 20, 2021 23:59:59 GMT (SP 1 -> END_TIME)
// * 1616644800000 --> Thursday, March 25, 2021 04:00:00 GMT or Thursday, March 25, 2021 00:00:00 EDT (SP 2 -> START_TIME)
// * 1619495999000 --> Tuesday, April 27, 2021 03:59:59 GMT or Monday, April 26, 2021 23:59:59 EDT (SP 2 -> END_TIME)
// export const EVENT_START_TIME = 1635916654000
export const EVENT_START_TIME = 1636952400000
export const EVENT_END_TIME = 1637384400000

export const isSSR = typeof window == undefined

export function detectMob() {
  if (typeof window !== "undefined") {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true
    } else {
      return false
    }
  }
}

export function detectOS() {
  if (navigator.userAgent.match(/Android/i)) {
    return "https://direct.executivemafia.com/play-download"
  } else if (navigator.userAgent.match(/iPhone/i)) {
    return "https://direct.executivemafia.com/ios-download"
  }
}

export function isDev() {
  return development
}

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}

export function formatCurrency(amount) {
  return new Intl.NumberFormat("us-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount)
}

export function useInput(defaultValue) {
  const [value, setValue] = useState(defaultValue)

  function handleChange(value) {
    setValue(value)
  }

  return {
    value: value,
    setValue: handleChange,
  }
}

export function toastMsg(title, message, classname) {
  toast(toastContent(), {
    autoClose: 5000,
    className: `toast-mafia ${classname}`,
  })

  function toastContent() {
    return (
      <div className="toast-container">
        <div className="toast-content">
          <h3 className="toast-title">{title}</h3>
          <p className="subcaption mb-0">{message}</p>
        </div>
      </div>
    )
  }
}

/**
 * To convert string newline to DOM breakline nodes
 */

export function convertBreakline(string) {
  return string.split("\n").map(function(item, i) {
    return (
      <span key={i}>
        {item}
        <br />
      </span>
    )
  })
}

/**
 * To get ordinal abbreviations of number
 */
export function getOrdinalAbbreviation(i) {
  var j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return "st"
  }
  if (j === 2 && k !== 12) {
    return "nd"
  }
  if (j === 3 && k !== 13) {
    return "rd"
  }
  return "th"
}

export function shuffleArray(array) {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

export function getRefLink(referral) {
  const queryString = qs.parse(referral)
  localStorage.setItem("referral_code", queryString.ref)
  return localStorage.getItem("referral_code")
}

export function scrollMeasurement() {
  var winheight =
    window.innerHeight ||
    (document.documentElement || document.body).clientHeight
  var docheight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  )
  var scrollTop =
    window.pageYOffset ||
    (document.documentElement || document.body.parentNode || document.body)
      .scrollTop
  var trackLength = docheight - winheight
  var pctScrolled = Math.floor((scrollTop / trackLength) * 100)
  return pctScrolled
}
