/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import Layout from "./src/components/layout"

//CUSTOM
import "./node_modules/bootstrap/dist/js/bootstrap.min.js"
import "./node_modules/bootstrap-select/dist/js/bootstrap-select.min.js"
import { detectMob } from "./src/Helper"
import "@styles/_index.scss"

// if (!detectMob()) {
//   import('@styles/_desktop.scss');
// } else {
//   import('@styles/_mobile.scss');
// }

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
