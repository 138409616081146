/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import ReactDynamicImport from "react-dynamic-import"
import { ToastContainer } from "react-toastify"
import { Router } from "@reach/router"
import { detectMob, useInput, toastMsg, EVENT_START_TIME } from "@src/Helper"
// import FreshChat from "react-freshchat"
import qs from "query-string"
import _ from "lodash"

// components import
import SEO from "@components/seo"
import Subscription from "@components/Subscription/Subscription"
import Unsubscribe from "@components/Subscription/Unsubscribe"
import Confirmation from "@components/Subscription/UnsubscribeConfirmation"
import Plan from "@components/Subscription/Plan"
import Success from "@components/Subscription/PaymentSuccess"
import Events from "@components/Events"

import TelegramIcon from "@icons/telegram-icon.png"
import { navigate } from "gatsby"

const NavbarMobile = () => {
  if (!detectMob()) {
    return import("./Navbar/Navbar")
  } else {
    return import("./Navbar/NavbarMobile")
  }
}

const footerLoader = () => {
  if (!detectMob()) {
    return import("./Footer/Footer")
  } else {
    return import("./Footer/FooterMobile")
  }
}

const editprofile = () => {
  if (!detectMob()) {
    return import("@components/EditProfile/EditProfile")
  } else {
    return import("@components/EditProfile/EditProfileMobile")
  }
}

const dashboard = () => {
  if (!detectMob()) {
    return import("@components/Dashboard/Dashboard")
  } else {
    return import("@components/Dashboard/DashboardMobile")
  }
}

const videoDetail = () => {
  if (!detectMob()) {
    return import("@components/Academy/Detail")
  } else {
    return import("@components/Academy/DetailMobile")
  }
}

const Navbar = ReactDynamicImport({ loader: NavbarMobile })
const Footer = ReactDynamicImport({ loader: footerLoader })
const EditProfile = ReactDynamicImport({ loader: editprofile })
const Dashboard = ReactDynamicImport({ loader: dashboard })
const VideoDetail = ReactDynamicImport({ loader: videoDetail })

const Layout = props => {
  const clientRoute = [
    "subscription",
    "edit-profile",
    "dashboard",
    "success",
    "detail",
  ]
  const routeMatch = clientRoute.filter(cr => {
    return props.location.pathname.includes(cr)
  })
  const hideLayout = () => {
    const querystring = qs.parse(props.location.search)
    return querystring.mobileonly && querystring.mobileonly == "true"
  }

  // STATES
  const newProfile = useInput(false)
  const [id, setId] = useState("")
  const [componentLoaded, setComponentLoaded] = useState(false)
  const [isLogout, setIsLogout] = useState(false)
  const [pageVisited, setPageVisited] = useState([])
  const [isVisible, setIsVisible] = useState(false)
  const [queryString, setQueryString] = useState("")

  const top = useRef(null)

  // EFFECTS
  useEffect(() => {
    if (detectMob()) {
      setId("m-toast")
    }
    setComponentLoaded(true)
    // window.addEventListener("scroll", toggleVisibility)
  }, [])

  useEffect(
    () => {
      if (!window.localStorage.access_token) {
        if (
          typeof window !== "undefined" &&
          !pageVisited.includes(window.location.pathname)
        ) {
          setPageVisited([...pageVisited, window.location.pathname])
        }
      }
    },
    typeof window !== "undefined" ? [window.location.pathname] : []
  )

  useEffect(() => {
    localStorage.setItem("register_flow", _.takeRight(pageVisited, 5).join(";"))
  }, [pageVisited])

  useEffect(() => {
    if (queryString && queryString != props.location.search) {
      navigate(`${props.location.pathname}${queryString}`)
    }
    console.log(props.location)
  }, [props.location])

  useEffect(() => {
    if (props.location.search) {
      setQueryString(props.location.search)
    }
  }, [props.location.search])

  // CUSTOM FUNCTIONS
  const setNewProfile = user => {
    newProfile.setValue(user)
  }

  // const toggleVisibility = () => {
  //   if (window.pageYOffset > 300) {
  //     setIsVisible(true)
  //     if (top.current !== null) {
  //       top.current.classList.add("show")
  //     }
  //   } else {
  //     setIsVisible(false)
  //     if (top.current !== null) {
  //       top.current.classList.remove("show")
  //     }
  //   }
  // }

  // const scrollToTop = () => {
  //   if (typeof window !== "undefined") {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     })
  //   }
  // }

  return (
    <>
      <div id={id}>
        {/* {componentLoaded && !hideLayout() && (
          <FreshChat token="a3745367-2849-428f-ab9d-0f7d3c1c4767" />
        )} */}
        {/* <Events location={props.location} isLogout={isLogout} /> */}
        <ToastContainer
          position="top-center"
          autoClose={6000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
        />
      </div>
      {/* {isVisible &&
      detectMob() &&
      typeof window !== "undefined" &&
      window.location.pathname === "/academy/course-list/" ? (
        <div
          className="d-flex align-items-center back-to-top fixed-bottom cursor-pointer"
          ref={top}
          onClick={() => scrollToTop()}
        >
          <span className="text-pure-white">Back to Top</span>{" "}
          <span className="mdi mdi-arrow-up text-pure-white ml-2"></span>
        </div>
      ) : (
        <></>
      )} */}
      <a
        className="telegram-admin-btn fixed-bottom cursor-pointer"
        style={{ right: "20px", bottom: "20px", left: "unset" }}
        // href="https://t.me/ExecutiveMafiaAdmin"
        href="https://t.me"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={TelegramIcon}
          width={60}
          height={60}
          alt="Telegram Admin Button"
        />
      </a>
      {!hideLayout() ? (
        <Navbar
          location={props.location}
          newProfile={newProfile.value}
          isLogout={setIsLogout}
        />
      ) : (
        <></>
      )}
      {routeMatch.length > 0 ? (
        <>
          <SEO title="Sign In" />
          <Router>
            <Subscription path="subscription" />
            <Plan path="subscription/plan" />
            <Confirmation path="subscription/unsubscribe/confirmation/:id" />
            <Unsubscribe path="subscription/unsubscribe/:id" />
            <VideoDetail path="academy/course-list/detail/:id" />
            <EditProfile path="edit-profile" setNewProfile={setNewProfile} />
            <Dashboard path="dashboard" setNewProfile={setNewProfile} />
            <Success location={props.location} path="success" />
          </Router>
        </>
      ) : (
        props.children
      )}
      {props.location.pathname !== "/events/halloween/" &&
        props.location.pathname !== "/events/halloween" &&
        !hideLayout() ? (
        <Footer />
      ) : (
        <></>
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
