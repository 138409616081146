import React from "react"
import PropTypes from "prop-types"

function Button({
  type,
  className,
  style,
  id,
  isDisabled,
  isLoading,
  loadingText,
  normalText,
  click,
  icon,
}) {
  return (
    <button
      className={className}
      style={style}
      id={id}
      type={type}
      disabled={isDisabled}
      onClick={() => {
        if (click) {
          click()
        } else {
        }
      }}
    >
      <div className="m-auto d-flex">
        <i className={`${icon}`} />
        <span
          className={`spinner-grow spinner-grow-md mr-2 ${
            isLoading ? "" : "d-none"
          }`}
          role="status"
          aria-hidden="true"
        ></span>
        <span className="m-auto">{isLoading ? loadingText : normalText}</span>
      </div>
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  normalText: PropTypes.string,
  click: PropTypes.func,
}

export default Button
