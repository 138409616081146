import React, { useEffect } from 'react';
import { Link, graphql, useStaticQuery, navigate } from 'gatsby';
import Image from 'gatsby-image';

// components import
import Loading from '@components/Loading';
import SEO from "../seo";

// assets import
import './_subscription.scss';
import { pricings } from '@src/Pricings';
import { getSubscription } from '@src/http/SubscriptionRepository';
import { useInput, detectMob } from '@src/Helper';

function Plan() {
  const packageIc = useStaticQuery(graphql`
    query queryPackageIc {
      freemium: file(relativePath: { eq: "logos/pricing-freemium.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      essential: file(relativePath: { eq: "logos/pricing-essential.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      executive: file(relativePath: { eq: "logos/pricing-executive.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      enterprise: file(relativePath: { eq: "logos/pricing-enterprise.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      sam: file(relativePath: { eq: "bots/sam_half_bot.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      telegram: file(relativePath: { eq: "logos/pricing-additional-tele.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      instagram: file(relativePath: { eq: "logos/pricing-additional-ig.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      default: file(relativePath: { eq: "logos/pricing-other.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  // states
  const subscription = useInput(null);
  const id = useInput('subscription-plan');

  // effects
  useEffect(() => {
    navigate('/subscription')
    handleGetSubscription();
    if (detectMob()) {
      id.setValue('m-subscription-plan');
    }
  }, [])

  // custom functions
  const handleGetSubscription = () => {
    getSubscription().then(res => {
      const data = res.data.data.filter(s => {
        return s.product.product_type === 'core_product';
      })
      if (data.length > 0) {
        subscription.setValue(data[0]);
      } else {
        subscription.setValue({});
      }
    }).catch(err => {
      console.log(err.response)
    })
  }

  const getProductShortNameLowerCase = (code) => {
    switch (code) {
      case 'CORE001':
        return 'essential';
      case 'CORE002':
        return 'executive';
      case 'CORE003':
        return 'enterprise';
      case 'CORE010':
        return 'essential';
      case 'CORE011':
        return 'executive';
      case 'CORE012':
        return 'enterprise';
      case 'STDL001':
        return 'sam';
      case 'STDL002':
        return 'sam';
      case 'EXTIG001':
        return 'instagram';
      case 'EXTMEM001':
        return 'telegram';
      default:
        return 'default';
    }
  }

  const convertProductCode = (code) => {
    switch (code) {
      case 'CORE001':
        return 'CORE010';
      case 'CORE002':
        return 'CORE011';
      case 'CORE003':
        return 'CORE012';
      case 'CORE010':
        return 'CORE010';
      case 'CORE011':
        return 'CORE011';
      case 'CORE012':
        return 'CORE012';
      default:
        return 'freemium';
    }
  }

  const renderCurrentPackage = () => {
    const ic = getProductShortNameLowerCase(subscription.value.product.product_code);
    return (
      <div className="plan-card-landscape">
        <SEO title="Subscription Plan" />
        <div className="row">
          <div className="col-md-3">
            <Image fluid={packageIc[ic].childImageSharp.fluid} className="plan-card-ic"/>
          </div>
          <div className="col-md-9 my-auto">
            <div className="d-flex flex-md-row flex-column">
              <h2 className="heading text-md-left text-center">{subscription.value.product.product_name}</h2>
              <span className={`badge ${subscription.value.expired_in_days === 'expired' ? 'badge-mafia-danger' : 'badge-mafia-success'} ml-md-2 mx-auto my-auto`}>{subscription.value.expired_in_days === 'expired' ? 'Expired' : 'Active'}</span>
            </div>
            <div className="body ml-md-0 mx-auto d-flex flex-md-row flex-column w-100 mb-4 mt-4">
              <p className="caption text-wisteria-purple mb-0">Paypal Subscribe ID</p>
              <p className="caption text-wisteria-purple mb-0">{subscription.value.subscr_id}</p>
            </div>
            {
              subscription.value.expired_in_days !== 'expired' &&
              <div className="text-md-left text-center">
                <Link to={`/subscription/unsubscribe/confirmation/${subscription.value.id}`} className="text-tomato-red body">Unsubscribe this package</Link>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  const renderListPackages = () => {
    let productCode = 0
    if (subscription.value.product) {
      productCode = convertProductCode(subscription.value.product.product_code);
    }
    return pricings.map((p) => {
      return (
        <div className="col-md-3 mt-md-0 mt-5" key={p.package_code}>
          {
            p.package_code === productCode &&
            <span id="active-package">Current Package</span>
          }
          <div className={`plan-card-portrait ${p.package_code === productCode ? 'active' : ''}`}>
            <Image fluid={packageIc[p.package_name].childImageSharp.fluid} className="plan-card-ic"/>
            <div className="plan-card-description text-center">
              <h2 className="heading text-capitalize">{p.package_name}</h2>
              <p className="caption text-200 text-meteoroid-purple">{p.package_desc}</p>
              <div className={`large-title pricing-tag my-3 ${p.package_name === "freemium" ? 'free' : ''}`}>
                <h2 className="large-title">{p.dollar}</h2>
                {
                  p.package_name !== 'freemium' &&
                  <div className="d-flex flex-column align-items-start justify-content-center">
                    <span className="subtitle line-height-auto">.{p.cent}</span>
                    <span className="caption text-meteoroid-purple">/month</span>
                  </div>
                }
              </div>
              <div className="plan-card-action">
                {
                  p.package_name !== 'freemium' &&
                  // <button className="button button-mafia-primary btn-block mt-3">Choose</button>
                  <div>
                    <a href="mailto:support@executivemafia.com" className="button button-mafia-primary btn-block button-sm mt-3">Contact Us</a>
                  </div>
                }
              </div>
              <ul className="plan-card-features">
                {
                  p.features.map(p => {
                    return (
                      <li className="body plan-feature-point" key={p}>{p}</li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      )
    })
  }
  
  if (subscription.value) {
    return (
      <div id={id.value} className="section-128">
        <div className="container">
          <h1 className="title text-center">Change Plan</h1>
          {
            subscription.value.product &&
            renderCurrentPackage()
          }
          <div className="row">
            {renderListPackages()}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="loading">
        <Loading />
      </div>
    )
  }
}

export default Plan;