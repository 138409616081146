import React, { useEffect, useRef } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Modal } from "react-bootstrap"
import _ from "lodash"

// components import
import Button from "@components/Button"
import SEO from "../seo"

// assets import
import "./_subscription.scss"
import {
  unsubscribe,
  getUnsubscribeFeedback,
} from "@src/http/SubscriptionRepository"
import { useInput, detectMob } from "@src/Helper"

function Unsubscribe({ id }) {
  const unsubIcons = useStaticQuery(graphql`
    {
      unsub01: file(
        relativePath: { eq: "icons/01-unsub-not-enough-time.png" }
      ) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      unsub02: file(relativePath: { eq: "icons/02-unsub-cant-afford.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      unsub03: file(
        relativePath: { eq: "icons/03-unsub-will-resubscribe.png" }
      ) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      unsub04: file(relativePath: { eq: "icons/04-unsub-not-satisfied.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      unsub05: file(
        relativePath: { eq: "icons/05-unsub-exm-service-bad.png" }
      ) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      unsub06: file(
        relativePath: { eq: "icons/06-unsub-ig-algorithm-bad.png" }
      ) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  // states
  const reason = useInput("")
  const isProceed = useInput(false)
  const reasonList = useInput({})
  const selectedReason = useInput(null)
  const rootId = useInput("unsubscribe-feedback")
  const isShowingModal = useInput(false)

  // ref
  const otherRef = useRef(null)
  const unsubscribeLink = useRef()

  // api call
  const submitUnsubscribe = () => {
    unsubscribe(reason.value, id)
      .then(res => {
        console.log(res)
        isProceed.setValue(false)
        // Go to PayPal Unsubscribe page
        unsubscribeLink.current.click()
      })
      .catch(err => {
        isProceed.setValue(false)
        console.log(err.response)
      })
  }

  const handleGetUnsubscribeFeedback = () => {
    getUnsubscribeFeedback()
      .then(res => {
        const tempObj = res.data.data
        for (let i = 0; i < tempObj.length; i++) {
          tempObj[i]["img"] = unsubIcons[`unsub0${i + 1}`]
        }
        reasonList.setValue(tempObj)
      })
      .catch(err => {
        console.log(err)
      })
  }

  // custom functions
  const handleOptionChange = id => {
    reason.setValue(id)
  }

  // const shuffle = (a) => {
  //   var j, x, i;
  //   for (i = a.length - 1; i > 0; i--) {
  //       j = Math.floor(Math.random() * (i + 1));
  //       x = a[i];
  //       a[i] = a[j];
  //       a[j] = x;
  //   }
  //   return a;
  // }
  // x = x.sort(() => Math.random() - 0.5)

  const renderReasonsTitle = () => {
    let unsubOptions
    if (detectMob()) {
      unsubOptions = _.chunk(reasonList.value, 2)
    } else {
      unsubOptions = _.chunk(reasonList.value, 3)
    }
    return unsubOptions.map((option, i) => {
      return (
        <div className="row mt-4">
          {option
            .sort(() => Math.random() - 0.5)
            .map(o => {
              return (
                <div className="col-xl-4 col-md-6 col-sm-12">
                  <div
                    className="unsubscribe-box d-flex"
                    onClick={() => {
                      selectedReason.setValue(o)
                    }}
                  >
                    <div className="m-xl-auto d-flex flex-md-column flex-row">
                      <Image
                        key={o.id}
                        fluid={o.img.childImageSharp.fluid}
                        alt="EXM Unsubscribe"
                        className="unsubscribe-icon"
                      />
                      <h2 className="unsubscribe-title subtitle text-md-center text-left ml-md-0 ml-2 mt-md-3 my-auto">
                        {o.title}
                      </h2>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      )
    })
  }

  const renderReasonOptions = () => {
    const { title, options, img } = selectedReason.value
    return (
      <div className="unsubscribe-detail">
        <div
          className="text-dolphin-blue d-flex cursor-pointer mb-3"
          onClick={() => selectedReason.setValue(null)}
        >
          <i className="mdi mdi-chevron-left mdi-36px" />{" "}
          <span className="my-auto">Let me see another options</span>
        </div>
        <Image
          fluid={img.childImageSharp.fluid}
          alt="EXM Unsubscribe"
          className="unsubscribe-icon mb-2"
        />
        <h2 className="unsubscribe-title heading text-center text-700 text-monday-blue">
          {title}
        </h2>
        <div className="custom-radio mt-5">
          <ul className="list-group">
            {options.map(o => {
              return (
                <li key={o.id}>
                  <input
                    type="radio"
                    id={`question-${o.id}`}
                    name="payment_method"
                    value={o.reason}
                    onChange={e => {
                      handleOptionChange(o.id)
                    }}
                  />
                  <label htmlFor={`question-${o.id}`}>{o.option}</label>

                  <div className="check"></div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  // effects
  useEffect(() => {
    if (detectMob()) {
      rootId.setValue("m-unsubscribe-feedback")
    }
    handleGetUnsubscribeFeedback()
  }, [])

  return (
    <>
      <Modal
        show={isShowingModal.value}
        contentClassName={`${detectMob() ? "mx-3" : ""} shiny-modal`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <form
            onSubmit={e => {
              e.preventDefault()
              isProceed.setValue(true)
              submitUnsubscribe()
            }}
          >
            <div
              className="d-flex position-absolute"
              style={{ top: "15px", right: "25px" }}
            >
              <span
                className="ml-auto cursor-pointer text-700 text-meteoroid-purple mdi mdi-close mdi-24px"
                onClick={() => {
                  isShowingModal.setValue(false)
                }}
              />
            </div>
            <p className="body mt-3">
              You will be directed to your PayPal account to stop the recurring
              payment on{" "}
              <strong style={{ fontSize: "18px" }}>
                PT. Executive Media Utama
              </strong>
              . Click on 'go to PayPal' button to continue the step.
            </p>
            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                className="button btn-block button-mafia-primary d-flex"
                isDisabled={isProceed.value}
                isLoading={isProceed.value}
                loadingText="Proceeding"
                normalText="Go To Paypal"
              />
              <a
                className="hidden"
                ref={unsubscribeLink}
                href="https://www.paypal.com/cgi-bin/webscr?cmd=_subscr-find&alias=XDV7P7WEYGMCW"
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <div id={rootId.value} className="section-128">
        <SEO title="Unsubscribe Feedback" />
        <div className="container">
          <form
            onSubmit={e => {
              e.preventDefault()
              isProceed.setValue(true)
              submitUnsubscribe()
            }}
          >
            <h1 className="title text-center section-heading">
              We are so sad <br />
              to see you leave
            </h1>
            <p className="body text-200 text-center">
              {" "}
              Before you do, please kindly tell us your reason why you decided
              to leave us.
            </p>
            {!selectedReason.value && renderReasonsTitle()}
            {selectedReason.value && renderReasonOptions()}
            <textarea
              ref={otherRef}
              name="other"
              id="other-reason"
              cols="30"
              rows="10"
              className="form-control"
              onChange={e => {
                reason.setValue(e.target.value)
              }}
            ></textarea>
            {selectedReason.value && (
              <div className="row mt-5">
                <div className="col-lg-6 pl-lg-0">
                  <Link
                    to="/subscription"
                    className="button btn-block button-mafia-default text-center"
                  >
                    I've Changed My Mind
                  </Link>
                </div>
                <div className="col-lg-6 pr-lg-0 mt-lg-0 mt-3">
                  <button
                    type="button"
                    className="button btn-block button-mafia-primary d-flex justify-content-center"
                    disabled={reason.value === "" || isProceed.value}
                    onClick={() => isShowingModal.setValue(true)}
                  >
                    Unsubscribe This Plan
                  </button>
                  {/* <button className="button btn-block button-mafia-primary">Unsubscribe This Plan</button> */}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  )
}

export default Unsubscribe
