import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import { Modal } from "react-bootstrap"
import ReactTooltip from "react-tooltip"

import {
  getMe,
  validateIfAvailableToPurchase,
} from "@repository/UserRepository"
import { EVENT_START_TIME } from "../Helper"
import CountDown from "@components/CountDown"
import { getToken } from "@src/http/client"
import { detectMob } from "@src/Helper"

function Events(props) {
  const checkIsCloseForever =
    typeof window !== "undefined" &&
    localStorage.getItem("is_close_forever_christmas")

  const [user, setUser] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isAvailableFromServer, setIsAvailableFromServer] = useState(false)
  const [isShowingModal, setIsShowingModal] = useState(
    checkIsCloseForever === null ? true : checkIsCloseForever == "false"
  )
  const [packages] = useState([
    {
      code: "EXTADS001",
      name: "1-Week Ads",
      description: "Advertise for a week full",
      price: "2.99 USD",
    },
    {
      code: "EXTADS002",
      name: "2-Week Ads",
      description: "Advertise for 2 weeks full",
      price: "4.99 USD",
    },
    {
      code: "EXTADS003",
      name: "1-Month Ads",
      description: "Advertise for a month full",
      price: "7.99 USD",
    },
  ])

  const images = useStaticQuery(graphql`
    {
      mafiaFAB: file(relativePath: { eq: "events/exm-event-fab.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      eventAfterText: file(
        relativePath: { eq: "icons/mafia-bday-after-text.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      eventBeforeText: file(
        relativePath: { eq: "icons/mafia-bday-before-text.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const formatProductCode = productCode => {
    return JSON.stringify({
      user_id: user.id,
      product_code: productCode,
    })
  }

  const renderSubscribeButton = packageId => {
    if (isLoggedIn && user !== null) {
      switch (packageId) {
        case "EXTADS001":
          return (
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
              className="paypal-button"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="E99DLW3D9LZYL"
              />
              <input
                type="hidden"
                name="custom"
                value={formatProductCode("EXTADS001")}
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribe_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          )
        case "EXTADS002":
          return (
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
              className="paypal-button"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="24MMDVDG77QLG"
              />
              <input
                type="hidden"
                name="custom"
                value={formatProductCode("EXTADS002")}
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribe_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          )
        case "EXTADS003":
          return (
            <form
              action="https://www.paypal.com/cgi-bin/webscr"
              method="post"
              target="_top"
              className="paypal-button"
            >
              <input type="hidden" name="cmd" value="_s-xclick" />
              <input
                type="hidden"
                name="hosted_button_id"
                value="LYBFYEV5TBU3G"
              />
              <input
                type="hidden"
                name="custom"
                value={formatProductCode("EXTADS003")}
              />
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/i/btn/btn_subscribe_LG.gif"
                border="0"
                name="submit"
                alt="PayPal - The safer, easier way to pay online!"
              />
              <img
                alt=""
                border="0"
                src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </form>
          )
        default:
          return <></>
      }
    } else {
      return (
        <button
          className="button button-sm button-event-checkout button-mafia-danger"
          onClick={() => {
            navigate("/signin")
            setIsShowingModal(false)
          }}
        >
          Subscribe
        </button>
      )
    }
  }

  const handleGetMe = () => {
    getMe()
      .then(res => {
        setUser(res.data)
        setIsLoggedIn(true)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    validateIfAvailableToPurchase().then(res => {
      setIsAvailableFromServer(res.data.is_valid)
    })
  }, [])

  useEffect(() => {
    if (getToken() !== null) {
      handleGetMe()
    } else {
      setIsLoggedIn(false)
    }
  }, [props.location.pathname, props.isLogout])

  const eventModal = () => {
    if (!detectMob()) {
      return (
        <Modal
          size="xl"
          containerClassName="exm-event-modal-parent"
          id="exm-event-modal"
          show={isShowingModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="d-flex text-pure-white my-3">
              <span
                className="ml-auto cursor-pointer"
                onClick={() => {
                  setIsShowingModal(false)
                }}
              >
                Close <span className="mdi mdi-close" />
              </span>
            </div>
            <div className="row exm-event-pricing">
              {packages.map((p, i) => {
                return (
                  <div
                    className={`col-3 christmas-card-col ${
                      i === 0 ? "offset-3" : ""
                    }`}
                  >
                    <div className="christmas-card">
                      <p className="subhead text-700 mb-1 text-pure-white">
                        {p.name}
                      </p>
                      <p className="caption mb-3 text-pure-white">
                        {p.description}
                      </p>
                      <div className="price-tag d-flex">
                        <div className="col-8 offset-4 my-auto">
                          <p className="subtitle text-pure-white mb-0">
                            {p.price}
                          </p>
                        </div>
                      </div>
                    </div>
                    {isAvailableFromServer && renderSubscribeButton(p.code)}
                  </div>
                )
              })}
            </div>
            <div className="row justify-content-end mt-5">
              <div className="christmas-modal-description px-3">
                <div className="border-bottom">
                  <p
                    className="d-inline text-pure-white text-200"
                    data-tip="
                    <b>Terms & Conditions</b><br>
                      • The Ads Package can only be purchased on <b>December 24th 2020 -  January 2nd 2021</b>.<br>
                      • The subscription system is <b>recurring</b>, so you will be charged periodically according to the <b>length of duration</b> you subscribed to.<br>
                      • The <b>Ads Package</b> gives you access to <b>1 ads slot</b> per subscription.<br>
                      • If you <b>un-subscribe</b>, you won't be able to purchase the <b>Ads Package</b> anymore since they will no longer be available.<br>"
                    data-html={true}
                  >
                    <span className="mdi mdi-information text-sunkist-yellow mdi-18px align-middle" />{" "}
                    Terms and conditions applied
                  </p>
                </div>
                <ReactTooltip html={true} />
                <p className="title text-golden-yellow mb-1">
                  Merry Christmas Everyone!
                </p>
                <p className="body text-pure-white">
                  2020 may not be the best year, but thank you for spending
                  another Christmas and New Year with Executive Mafia. To
                  express our gratitude, we have prepared a special Year-End
                  package deal that is only available for limited amount of
                  time. Grab it fast and don't miss out!
                </p>
                <CountDown />
                <Img
                  className="exm-handwriting"
                  fluid={
                    new Date().getTime() < EVENT_START_TIME
                      ? images.eventBeforeText.childImageSharp.fluid
                      : images.eventAfterText.childImageSharp.fluid
                  }
                  alt="Executive Mafia Birthday Event."
                />
                <div className="form-check mx-auto mt-5">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="do-not-show-this-again"
                    defaultChecked={checkIsCloseForever == "true"}
                    onChange={e => {
                      typeof window !== "undefined" &&
                        localStorage.setItem(
                          "is_close_forever_christmas",
                          e.target.checked
                        )
                    }}
                  />
                  <label
                    className="form-check-label text-pure-white"
                    for="do-not-show-this-again"
                  >
                    Do not show this again
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )
    } else {
      return (
        <Modal
          size="lg"
          id="m-exm-event-modal"
          show={isShowingModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="d-flex text-pure-white mb-1 mt-3">
              <span
                className="ml-auto cursor-pointer"
                onClick={() => {
                  setIsShowingModal(false)
                }}
              >
                Close <span className="mdi mdi-close" />
              </span>
            </div>
            <div style={{ zIndex: 999, position: "relative" }}>
              <p className="large-title text-golden-yellow">Merry Christmas</p>
              <p className="body text-pure-white mb-3">
                2020 may not be the best year, but thank you for spending
                another Christmas and New Year with Executive Mafia.
                <br />
                <br />
                To express our gratitude, we have prepared a special Year-End
                package deal that is only available for limited amount of time.
                Grab it fast and don't miss out!
              </p>
              <Link
                to="/events/christmas"
                className="button button-event-checkout button-mafia-danger"
                onClick={() => setIsShowingModal(false)}
              >
                Check the Deal
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      )
    }
  }

  return (
    <>
      <div>
        {eventModal()}
        <div />
        <div />
        <div
          id="exm-event-fab"
          onClick={() => {
            setIsShowingModal(true)
          }}
        >
          <Img
            className="exm-little-piranha"
            fluid={images.mafiaFAB.childImageSharp.fluid}
            alt="Executive Mafia Birthday Event."
          />
        </div>
      </div>
    </>
  )
}

export default Events
