import { httpClient as client, getToken } from "./client"

export function getSubscription() {
  return new Promise((resolve, reject) => {
    client
      .get('/mafia/subscription', 
        {headers: { Authorization: `Bearer ${getToken()}` }},
      )
      .then(res => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
  })
}

export function unsubscribe(unsubscribe_feedback_option_id, subscription_id) {
  return new Promise((resolve, reject) => {
    client
      .post('/mafia/unsubscribefeedback',
        {unsubscribe_feedback_option_id, subscription_id},
        {headers: {Authorization: `Bearer ${getToken()}`}}
      )
      .then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
  })
}

export function getUnsubscribeFeedback() {
  return new Promise((resolve, reject) => {
    client
      .get('/mafia/unsubscribefeedback/options',
        {headers: { Authorization: `Bearer ${getToken()}` }}
      )
      .then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
  })
}
