import React from 'react';
import PropTypes from 'prop-types';

// assets import
import '@styles/_alert.scss';

function Alert(props) {
  return (
    <div className={`alert alert-mafia-${props.style} ${props.className} subcaption`} role="alert">
      {props.text} 
      {
        props.linkText &&
        <span
          className="alert-link"
          onClick={async () => {
            props.onLinkClick();
        }}>
          {props.linkText}
        </span>
      }
    </div>
  )
}

Alert.propTypes = {
  style: PropTypes.string,
  className: PropTypes.string,
  linkText: PropTypes.string,
  onLinkClick: PropTypes.func
}

export default Alert;