import { httpClient as client, getToken } from "./client"

export function login(email, password) {
  return new Promise((resolve, reject) => {
    client
      .post("/mafia/auth/login", {
        email: email,
        password: password,
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function signup(
  email,
  fullname,
  password,
  telegram_username,
  country,
  referral_by,
  otp,
  register_flow
) {
  return new Promise((resolve, reject) => {
    client
      .post(`/mafia/auth/register`, {
        email,
        fullname,
        password,
        telegram_username,
        country,
        referral_by,
        otp,
        register_flow,
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function requestOTP(telegramUsername) {
  return new Promise((resolve, reject) => {
    client
      .post(`/mafia/auth/requesttelegramotp`, {
        telegram_username: telegramUsername,
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function loginWithGoogle(id_token) {
  return new Promise((resolve, reject) => {
    client
      .post("/mafia/auth/google", { id_token })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getGoogleAuthClientId() {
  return new Promise((resolve, reject) => {
    client
      .get("/mafia/auth/google")
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function postGoogleAuth(id_token) {
  return new Promise((resolve, reject) => {
    client
      .post(`/mafia/auth/google`, { id_token })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function storeAdditionalInformation(
  telegram_username,
  country,
  referral_by,
  otp
) {
  return new Promise((resolve, reject) => {
    client
      .post(
        `/mafia/setadditionalinformation`,
        { telegram_username, country, referral_by, otp },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getMe(refreshStorage = false) {
  return new Promise((resolve, reject) => {
    // if (refreshStorage === true) {
    //   localStorage.removeItem("user_data")
    // }

    client
      .post(
        "/mafia/auth/me",
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then(res => {
        if (refreshStorage === true) {
          localStorage.setItem("user_data", JSON.stringify(res.data))
        }

        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getDashboardData() {
  return new Promise((resolve, reject) => {
    client
      .get("/mafia/dashboard", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function updateProfile(data) {
  return new Promise((resolve, reject) => {
    client
      .post("/mafia/editprofile", data, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function updateEmail(newEmail) {
  return new Promise((resolve, reject) => {
    client
      .post(
        `/mafia/changeemail`,
        { new_email: newEmail },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function updatePassword(data) {
  return new Promise((resolve, reject) => {
    client
      .post(`/mafia/changepassword`, data, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function verifyNewEmail(token) {
  return new Promise((resolve, reject) => {
    client
      .post(`/mafia/verifychangeemail`, token, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function verifyEmail(token) {
  return new Promise((resolve, reject) => {
    client
      .post(`/mafia/verifyregisteremail`, token, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function resendVerifyNewEmail() {
  return new Promise((resolve, reject) => {
    client
      .post(
        `/mafia/resendchangeemail`,
        {},
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function cancelEmailChange() {
  return new Promise((resolve, reject) => {
    client
      .post(
        `/mafia/cancelchangeemail`,
        {},
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function resendVerificationEmail() {
  return new Promise((resolve, reject) => {
    client
      .post(
        `/mafia/resendemailverification`,
        {},
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function logout() {
  return new Promise((resolve, reject) => {
    client
      .post(
        `/mafia/auth/logout`,
        {},
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function unsubscribeNewsletter(email) {
  return new Promise((resolve, reject) => {
    client
      .get(`/mafia/unsubscribe/${email}`, {
        headers: { Authorization: `Bearer ${getToken()}` },
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function forgotPassword(email) {
  return new Promise((resolve, reject) => {
    client
      .post(`/mafia/password/forgot`, {
        email: email,
      })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function resetPassword(payload) {
  return new Promise((resolve, reject) => {
    client
      .post(`/mafia/password/reset`, payload)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function validateIfAvailableToPurchase() {
  return new Promise((resolve, reject) => {
    client
      .get(`/mafia/event/validation?set_true=true`)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getTestimonials(data) {
  return new Promise((resolve, reject) => {
    client
      .get(`/mafia/testimonial`, {
        params: { instagram_usernames: data },
      })
      .then(res => {
        resolve(res)
        // instagrams.setValue(res.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
