import React, { useEffect, useState } from "react"
import { Link } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import qs from "query-string"

// components import
import Loading from "@components/Loading"
import SEO from "../seo"

// assets import
import "./_subscription.scss"
import { getSubscription } from "@src/http/SubscriptionRepository"
import { useInput, detectMob } from "@src/Helper"
import { data } from "jquery"

function UnsubscribeConfirmation({ id, location }) {
  const [productId, setProductId] = useState()
  const bg = useStaticQuery(graphql`
    query queryConfirmationBg {
      freemium: file(relativePath: { eq: "logos/pricing-freemium.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      essential: file(relativePath: { eq: "logos/pricing-essential.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      executive: file(relativePath: { eq: "logos/pricing-executive.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      enterprise: file(relativePath: { eq: "logos/pricing-enterprise.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      sam: file(relativePath: { eq: "bots/sam_half_bot.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      telegram: file(
        relativePath: { eq: "logos/pricing-additional-tele.png" }
      ) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      instagram: file(relativePath: { eq: "logos/pricing-additional-ig.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      default: file(relativePath: { eq: "logos/pricing-other.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  // states
  const subscription = useInput(null)
  const elementId = useInput("unsubscribe-confirmation")

  // api calls
  const handleGetSubscription = () => {
    getSubscription()
      .then(res => {
        // console.log(res.data.data);
        const temp = res.data.data.filter(sub => {
          return sub.id.toString() == location.pathname.split("/")[4].toString()
        })
        subscription.setValue(temp[0])
      })
      .catch(err => {
        console.log(err.response)
      })
  }

  // effects
  useEffect(() => {
    // setProductId(location.pathname.split('/')[4]);
    handleGetSubscription()
    if (detectMob()) {
      elementId.setValue("m-unsubscribe-confirmation")
    }
  }, [])

  // custom functions
  const getProductShortNameLowerCase = code => {
    switch (code) {
      case "CORE001":
        return "essential"
      case "CORE002":
        return "executive"
      case "CORE003":
        return "enterprise"
      case "CORE010":
        return "essential"
      case "CORE011":
        return "executive"
      case "CORE012":
        return "enterprise"
      case "STDL001":
        return "sam"
      case "STDL002":
        return "sam"
      case "EXTIG001":
        return "instagram"
      case "EXTMEM001":
        return "telegram"
      default:
        return "default"
    }
  }

  const renderSubscriptionCard = () => {
    const productName = getProductShortNameLowerCase(
      subscription.value.product.product_code
    )
    return (
      <div className="card card-white card-sm subscription-card d-flex">
        <div className="badge badge-mafia-sm badge-mafia-warning mt-auto mr-auto">
          {subscription.value.expired_in_days}
        </div>
        <div className="mb-auto mt-2">
          <div className="d-flex mt-1">
            <p className="caption text-wisteria-purple mr-5 mb-1">
              Paypal Subscribe ID
            </p>
            <p className="caption text-wisteria-purple mb-0">
              {subscription.value.subscr_id}
            </p>
          </div>
          <h2 className="heading">{subscription.value.product.product_name}</h2>
        </div>
        {/* <Link to="/#" className="link-positive">View package details</Link>
        <Link to="/subscription/plan" className="link-positive">Change package</Link>
        <Link to={`/subscription/unsubscribe/${subscription.value.id}`} className="link-negative">Unsubscribe this package</Link> */}
        <Image
          fluid={bg[productName].childImageSharp.fluid}
          className="subscription-card-bg"
        />
      </div>
    )
  }

  if (subscription.value) {
    return (
      <div id={elementId.value}>
        <SEO title="Unsubscribe Confirmation" />
        <div className="container section-128">
          <div className="section-heading"></div>
          <h1 className="title text-tomato-red text-center">Are you sure?</h1>
          <p className="body text-center">
            You are about to unsubscribe the package below
          </p>
          <div className="row mt-3">
            <div className="col-md-6 offset-md-3">
              {renderSubscriptionCard()}
            </div>
          </div>
          <h2 className="subtitle mt-5">
            We would like to confirm your decision before you go...
          </h2>
          <ul className="list-unstyled">
            {/* <li><small className="text-wisteria-purple text-200"><sup>*</sup> If you are tired of getting email notifications from us, you can disable them here</small></li> */}
            <li className="body">
              <small className="text-wisteria-purple text-200">
                <sup>*</sup> If you have any problems on your plan, you can{" "}
                <a
                  href="https://t.me/ExecutiveMafiaAdmin"
                  target="_blank"
                  className="text-dolphin-blue"
                >
                  contact our support
                </a>
                .
              </small>
            </li>
            <li className="body">
              <small className="text-tomato-red text-700">
                <sup>*</sup> After unsubscribing, you will not be able to
                recover your plan, and you have to re-subscribe again.
              </small>
            </li>
          </ul>
          <div className="mt-3">
            <Link
              to="/subscription"
              className="button button-mafia-default btn-block button-sm mr-3 text-center"
            >
              I've Changed My Mind
            </Link>
            <Link
              to={`/subscription/unsubscribe/${id}`}
              className="button button-mafia-danger btn-block button-sm text-center"
            >
              I want to Unsubscribe
            </Link>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="loading">
        <Loading />
      </div>
    )
  }
}

export default UnsubscribeConfirmation
