import React, {useEffect} from 'react'
import {useInput, detectMob, EVENT_START_TIME, EVENT_END_TIME} from '@src/Helper'

function CountDown() {
  const days = useInput(0)
  const hours = useInput(0)
  const minutes = useInput(0)
  const seconds = useInput(0)

  useEffect(() => {
    let countdown = null
    var endTime = new Date().getTime() >= EVENT_START_TIME ? EVENT_END_TIME : EVENT_START_TIME
    countdown = setInterval(() => {
      var currentTime = new Date().getTime()
      var time = endTime - currentTime

      if (time <= 0) {
        days.setValue(0)
        hours.setValue(0)
        minutes.setValue(0)
        seconds.setValue(0)
        clearInterval(countdown)
      } else {
        days.setValue(Math.floor(time / (1000 * 60 * 60 * 24)))
        hours.setValue(
          Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        )
        minutes.setValue(Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)))
        seconds.setValue(Math.floor((time % (1000 * 60)) / 1000))
      }
    }, 500)
  }, [])

  return (
    <div className={detectMob() ? 'countdown subtitle text-700 text-center mb-3' : 'countdown'}>
      <span className={detectMob() ? 'countdown-number' : 'countdown-number subtitle text-700'}>
        {days.value <= 9 ? `0${days.value}` : days.value}
      </span>{" "}
      <span className={typeof window !== 'undefined' && window.location.pathname === '/events/exm-birthday' ? 'text-carbon-black subtitle' : 'text-pure-white subtitle'}>:</span>{" "}
      <span className={detectMob() ? 'countdown-number' : 'countdown-number subtitle text-700'}>
        {hours.value <= 9 ? `0${hours.value}` : hours.value}
      </span>{" "}
      <span className={typeof window !== 'undefined' && window.location.pathname === '/events/exm-birthday' ? 'text-carbon-black subtitle' : 'text-pure-white subtitle'}>:</span>{" "}
      <span className={detectMob() ? 'countdown-number' : 'countdown-number subtitle text-700'}>
        {minutes.value <= 9 ? `0${minutes.value}` : minutes.value}
      </span>{" "}
      <span className={typeof window !== 'undefined' && window.location.pathname === '/events/exm-birthday' ? 'text-carbon-black subtitle' : 'text-pure-white subtitle'}>:</span>{" "}
      <span className={detectMob() ? 'countdown-number' : 'countdown-number subtitle text-700'}>
        {seconds.value <= 9 ? `0${seconds.value}` : seconds.value}
      </span>
    </div>
  )
}

export default CountDown;
