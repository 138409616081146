import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Router, Link } from "@reach/router"
import Image from "gatsby-image"

// components import
import Loading from "@components/Loading"
import Unsubscribe from "@components/Subscription/Unsubscribe"
import SEO from "../seo"

// assets import
import "./_subscription.scss"
import { getSubscription } from "@src/http/SubscriptionRepository"
import { pricings } from "@src/Pricings"
import { useInput, detectMob } from "@src/Helper"

function Subscription() {
  const bg = useStaticQuery(graphql`
    query queryBg {
      freemium: file(relativePath: { eq: "logos/pricing-freemium.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      essential: file(relativePath: { eq: "logos/pricing-essential.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      executive: file(relativePath: { eq: "logos/pricing-executive.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      enterprise: file(relativePath: { eq: "logos/pricing-enterprise.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      sam: file(relativePath: { eq: "bots/sam_half_bot.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      telegram: file(
        relativePath: { eq: "logos/pricing-additional-tele.png" }
      ) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      instagram: file(relativePath: { eq: "logos/pricing-additional-ig.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      jasmineKit: file(relativePath: { eq: "logos/jasmine-kit.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      sophieKit: file(relativePath: { eq: "logos/sophie-kit.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      theoKit: file(relativePath: { eq: "logos/theo-kit.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      ads7: file(relativePath: { eq: "logos/ads-package-7.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      ads14: file(relativePath: { eq: "logos/ads-package-14.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      ads30: file(relativePath: { eq: "logos/ads-package-30.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      default: file(relativePath: { eq: "logos/pricing-other.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  // states
  const subscriptions = useInput(null)
  const id = useInput("subscription")

  // effects
  useEffect(() => {
    handleGetSubscription()
    if (detectMob()) {
      id.setValue("m-subscription")
    }
  }, [])

  // custom functions
  const handleGetSubscription = () => {
    getSubscription()
      .then(res => {
        subscriptions.setValue(res.data.data)
      })
      .catch(err => {
        console.log(err.response)
      })
  }

  const getProductShortNameLowerCase = code => {
    switch (code) {
      case "CORE001":
        return "essential"
      case "CORE002":
        return "executive"
      case "CORE003":
        return "enterprise"
      case "CORE010":
        return "essential"
      case "CORE011":
        return "executive"
      case "CORE012":
        return "enterprise"
      case "CORE016":
        return "jasmineKit"
      case "CORE017":
        return "sophieKit"
      case "CORE018":
        return "theoKit"
      case "STDL001":
        return "sam"
      case "STDL002":
        return "sam"
      case "EXTIG001":
        return "instagram"
      case "EXTMEM001":
        return "telegram"
      case "EXTADS001":
        return "ads7"
      case "EXTADS002":
        return "ads14"
      case "EXTADS003":
        return "ads30"
      default:
        return "default"
    }
  }

  const renderSubscriptions = () => {
    return subscriptions.value.map(sub => {
      const productName = getProductShortNameLowerCase(sub.product.product_code)
      return (
        <div className="col-md-6 mt-md-0 mt-4" key={sub.id}>
          <div className="card card-white card-sm subscription-card d-flex">
            <div className="badge badge-mafia-sm badge-mafia-warning mr-auto">
              {sub.expired_in_days}
            </div>
            <div className="d-flex mt-3">
              <p className="caption text-wisteria-purple mr-5">
                Paypal Subscribe ID
              </p>
              <p className="caption text-wisteria-purple">{sub.subscr_id}</p>
            </div>
            <h2 className="heading mb-3">{sub.product.product_name}</h2>
            {/* <Link to="/#" className="link-positive">View package details</Link> */}
            {/* {productName !== "sam" &&
              productName !== "instagram" &&
              productName !== "telegram" &&
              productName !== "ads7" &&
              productName !== "ads14" &&
              productName !== "ads30" && (
                <Link
                  to="/subscription/plan"
                  className="body text-dolphin-blue mb-2 d-none"
                >
                  Change package
                </Link>
              )} */}
            {Number(sub.expired_in_days.split(" ")[0]) > 0 && (
              <Link
                to={`/subscription/unsubscribe/confirmation/${sub.id}`}
                className="body text-tomato-red"
                style={{zIndex: "2"}}
              >
                Unsubscribe this package
              </Link>
            )}
            <Image
              fluid={bg[productName].childImageSharp.fluid}
              className="subscription-card-bg"
            />
          </div>
        </div>
      )
    })
  }

  if (subscriptions.value) {
    return (
      <div id={id.value} className="section-128">
        <SEO title="Subscription" />
        <div className="container">
          <div className="section-heading">
            <h1 className="title text-center">Subscription Detail</h1>
          </div>
          <div className="row">
            {subscriptions.value.length > 0 ? (
              renderSubscriptions()
            ) : (
              <div className="col-12 mt-5">
                <p className="subtitle text-center text-200">
                  You are not subscribed to one of our packages. Subscribe{" "}
                  <Link
                    to="/pricing"
                    className="text-dolphin-blue underline text-700"
                  >
                    here
                  </Link>
                </p>
              </div>
            )}
            {/* <div className="col-6">
              <div className="card card-white card-sm subscription-card d-flex">
                <div className="badge badge-sm badge-warning ml-auto">20 Days</div>
                <div className="d-flex">
                  <p className="paypal-subscribe-id flex-1">Paypal Subscribe ID</p>
                  <p className="paypal-subscribe-id flex-2">I-XKCT7FKHMNK3</p>
                </div>
                <h2 className="subtitle">Grandpa Sam Research Tool</h2>
                <Link to="/subscription" className="link-positive">View package details</Link>
                <Link to="/subscription" className="link-positive">Change package</Link>
                <Link to="/subscription" className="link-negative">Unsubscribe this package</Link>
                <Image fluid={bg.essential.childImageSharp.fluid} className="subscription-card-bg"/>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="loading">
        <Loading />
      </div>
    )
  }
}

export default Subscription
