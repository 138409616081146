import { EVENT_END_TIME, EVENT_START_TIME } from "@src/Helper"

export const pricings = [
  {
    package_name: "freemium",
    package_desc:
      "We are waiting for you to come out and test the waters. You'll love it!",
    package_code: "",
    dollar: "0",
    cent: "00",
    features: [
      "Instagram Analytics",
      "Limited Access Engagement Groups",
      "1 Instagram Account",
    ],
    allFeatures: [
      "Instagram Analytics",
      "Limited Access Engagement Groups",
      "1 Instagram Account",
      "1 Telegram Account",
      "2 Warns Limit",
      "EXM Ads Room (Read-Only)",
    ],
    the_rest_features_count: 2,
    three_months_dollar: "0",
    three_months_cent: "00",
    three_months_total: "",
  },
  {
    package_name: "essential",
    package_desc: "Ready to swim through the river? Let's gather your forces!",
    package_code: "CORE010",
    dollar: "15",
    cent: "99",
    features: [
      "Instagram Analytics",
      "Full Access Engagement Groups",
      "1 Instagram Account",
    ],
    allFeatures: [
      "Instagram Analytics",
      "Full Access Engagement Groups",
      "1 Instagram Account",
      "1 Telegram Account",
      "3 Warns Limit",
      "EXM Ads Room (Read-Only)",
      "EXM Lounge Room",
      "EXM Collab Room",
      "EXM 101 Room",
    ],
    the_rest_features_count: 6,
    three_months_dollar: "14",
    three_months_cent: "49",
    three_months_total: "43.47",
  },
  {
    package_name: "executive",
    package_desc:
      "Swim and hunt! Killing more than two birds with just one stone!",
    package_code: "CORE011",
    dollar: "27",
    cent: "99",
    features: [
      "Instagram Analytics",
      "Viral Post Tracker",
      "Hashtags Generator",
      "Full Access Engagement Groups",
    ],
    allFeatures: [
      "Instagram Analytics",
      "Viral Post Tracker",
      "Hashtags Generator",
      "Full Access Engagement Groups",
      "3 Instagram Account",
      "1 Telegram Account",
      "4 Warns Limit",
      "EXM Ads Room (1 time/day)",
      "EXM Lounge Room",
      "EXM Collab Room",
      "EXM 101 Room",
    ],
    the_rest_features_count: 7,
    three_months_dollar: "25",
    three_months_cent: "99",
    three_months_total: "77.97",
  },
  {
    package_name: "enterprise",
    package_desc: "Unstoppable hunter, lead the way and go further!",
    package_code: "CORE012",
    dollar: "69",
    cent: "99",
    features: [
      "Instagram Analytics",
      "Viral Post Tracker",
      "Hashtags Generator",
      "Hashtag Analytics",
    ],
    allFeatures: [
      "Instagram Analytics",
      "Viral Post Tracker",
      "Hashtags Generator",
      "Hashtag Analytics",
      "IG Growth Tracker",
      "Full Access Engagement Groups",
      "5 Instagram Account",
      "2 Telegram Account",
      "6 Warns Limit",
      "EXM Ads Room (2 times/day)",
      "EXM Lounge Room",
      "EXM Collab Room",
      "EXM 101 Room",
    ],
    the_rest_features_count: 9,
    three_months_dollar: "63",
    three_months_cent: "99",
    three_months_total: "191.97",
  },
]

const checkDate =
  new Date().getTime() <= EVENT_END_TIME &&
  new Date().getTime() >= EVENT_START_TIME

export const purchaseOverview = [
  {
    name: "Personal Package",
    package_name: "personal",
    package_code: "CORE026",
    price: checkDate ? "13.99" : "15.99",
    features: [
      "Engagement Group Features",
      "Full-access to Analytic Tools",
      "Full-access to EXM Rooms",
    ],
    three_months_price: checkDate ? "11.99" : "13.99",
    three_months_total: checkDate ? "35.99" : "41.99",
    three_months_package_code: "CORE028",
  },
  {
    name: "Manager Package",
    package_name: "manager",
    package_code: "CORE027",
    price: checkDate ? "23.99" : "27.99",
    features: [
      "Engagement Group Features",
      "Full-access to Analytic Tools",
      "Full-access to EXM Rooms",
    ],
    three_months_price: checkDate ? "21.99" : "25.99",
    three_months_total: checkDate ? "65.99" : "77.99",
    three_months_package_code: "CORE029",
  },
  {
    name: "Like Engagement Package",
    package_name: "like",
    package_code: "CORE018",
    price: checkDate ? "8.99" : "9.99",
    features: [
      "Like Engagement Group",
      "Instagram Analytics",
      "Hashtag Generator",
      "EXM Collab",
      "EXM Ads",
      "1 Instagram Account",
    ],
    three_months_price: checkDate ? "8.99" : "9.99",
    three_months_total: checkDate ? "8.99" : "9.99",
  },
  {
    name: "Mention Engagement Package",
    package_name: "mention",
    package_code: "CORE016",
    price: checkDate ? "6.99" : "7.99",
    features: [
      "Mention Engagement Group",
      "Instagram Analytics",
      "Hashtag Generator",
      "EXM Collab",
      "EXM Ads",
      "1 Instagram Account",
    ],
    three_months_price: checkDate ? "6.99" : "7.99",
    three_months_total: checkDate ? "6.99" : "7.99",
  },
  {
    name: "Comment Engagement Package",
    package_name: "comment",
    package_code: "CORE017",
    price: checkDate ? "6.99" : "7.99",
    features: [
      "Comment Engagement Group",
      "Instagram Analytics",
      "Hashtag Generator",
      "EXM Collab",
      "EXM Ads",
      "1 Instagram Account",
    ],
    three_months_price: checkDate ? "6.99" : "7.99",
    three_months_total: checkDate ? "6.99" : "7.99",
  },
  {
    name: "Analytic Tools Package",
    package_name: "analytic",
    package_code: "STDL001",
    price: checkDate ? "8.99" : "9.99",
    features: [
      "Hashtag Generator",
      "Hashtag Analytics",
      "Viral Post Tracker",
      "IG Growth Tracker",
      "Instagram Analytics",
    ],
    three_months_price: checkDate ? "8.99" : "9.99",
    three_months_total: checkDate ? "8.99" : "9.99",
  },
]
